import AsyncStatus from "../../../utils/async";
import submitAgentInterest from "../../../api/mutations/submitAgentInterest";
import { allCountriesOptions } from "../../../data";
import NotificationsMixin from "../../ui/NotificationsMixin";
import mixins from "vue-typed-mixins";
import { rethrowPossibleApolloErrorAsUserMessage } from "../../../api/apolloErrorToUserMessage";
function createEmptyFormValues() {
    return {
        firstName: "",
        lastName: "",
        emailAddress: "",
        phoneNumber: "",
        country: "",
    };
}
export default mixins(NotificationsMixin).extend({
    data: function () {
        return {
            honeyPotValue: "",
            countries: allCountriesOptions,
            form: createEmptyFormValues(),
            status: new AsyncStatus(),
        };
    },
    methods: {
        onSubmit: function () {
            var _this = this;
            this.$validator.validate().then(function (valid) {
                if (valid) {
                    _this.status.run(_this.$apollo
                        .mutate(submitAgentInterest(_this.form))
                        .catch(rethrowPossibleApolloErrorAsUserMessage));
                }
            });
        },
    },
});
